import {v2Request} from './v2Request';


export const userThumbV2Api = {


  async toggleThumb({toUserId}) {
    return v2Request.post(`userThumb/toggleThumb`,{toUserId});
  }


};
