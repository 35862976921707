<template>
  <div class="rank-page">
    <rank-page-my-rank-info/>

    <div class="rank-tab">
      <van-tabs ref="tab" :active="$store.state.rankPage.tab.creatorRank" @input="onTabChange" animated swipeable sticky
                offset-top="10.667vw" @scroll="onTabScroll">
        <van-tab title="上传榜" :name="1">
          <rank-page-rank-list unit="份" :items="$store.state.rankPage.creatorRank.records" :total="0"/>
        </van-tab>
        <van-tab title="被浏览" :name="2">
          <rank-page-rank-list unit="份" :items="$store.state.rankPage.creatorRank.records" :total="0"/>
        </van-tab>
        <van-tab title="被推荐" :name="3">
          <rank-page-rank-list unit="份" :items="$store.state.rankPage.creatorRank.records" :total="0"/>
        </van-tab>
        <van-tab title="被收藏" :name="4">
          <rank-page-rank-list unit="份" :items="$store.state.rankPage.creatorRank.records" :total="0"/>
        </van-tab>
        <van-tab title="人气榜" :name="5">
          <rank-page-rank-list unit="" :items="$store.state.rankPage.creatorRank.records" :total="0"/>
        </van-tab>
      </van-tabs>
    </div>

  </div>
</template>

<script>
import RankPageMyRankInfo from "./rankPageMyRankInfo";
import RankPageRankList from "./rankPageRankList";

export default {
  components: {RankPageRankList, RankPageMyRankInfo},
  watch: {
    '$store.state.rankPage.header.tab'(val) {
      if (val === 'creator') {
        this.$refs.tab.resize()
      }
    }
  },


  methods: {
    // 切换tab
    onTabChange(tab) {
      this.$store.dispatch('rankPage/switchCreatorRankTab', tab)
    },


    onTabScroll(e) {
      this.$store.dispatch('rankPage/setTabCreatorRankFixed', e.isFixed)
    },
  }

}
</script>

<style scoped lang="less">
.rank-page {
  height: calc(100vh - 40px - 50px);
  overflow: auto;
  background: #f4f4f4;
  width: 100vw;
}


.rank-tab {
  margin-top: 42px;
  background: #fff;
}

::v-deep .van-tabs__nav {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
