<template>
  <div class="rank-page-rank-list">
    <template v-if="myRankInfo">
      <rank-page-rank-item :item.sync="myRankInfo" :index="myRankInfo.rankIndex" :isLast="false" :unit="unit"/>
      <div class="my-rank-info-divider"></div>
    </template>
    <van-list :loading="loading" @load="onLoadMore" :finished="items.length>=total" finished-text="灵感到底了~"
              loading-text="灵感加载中">
      <rank-page-rank-item v-for="(item,index) in items" :index="item.rankIndex || index+1"
                           :isLast="index === items.length - 1"
                           :unit="unit" :item.sync="item" :key="item.rankIndex"/>
    </van-list>
    <rank-item-skeleton v-for="i in 10" :key="i" v-if="loading"/>
  </div>
</template>

<script>
import RankPageRankItem from "./rankPageRankItem";
import RankItemSkeleton from './rankItemSkeleton'

export default {
  components: {RankPageRankItem, RankItemSkeleton},
  props: ['myRankInfo', 'items', 'unit', 'loading', 'total'],
  methods: {

    // 加载更多
    onLoadMore() {
      this.$emit('loadMore')
    }

  }

}
</script>

<style scoped lang="less">
.my-rank-info-divider {
  height: 1px;
  background: #e9e9e9;
  margin: 0 27px;
}

.rank-page-rank-list {
  //padding-bottom: 12px;
}

.rank-page-list-holder {
  width: 100vw;
  height: calc(100vh - 40px - 50px - 44px);
}

</style>
