<template>
  <v-header useSlot :theme="$store.state.rankPage.header.deep" :useHolder="$store.state.rankPage.header.tab!=='task'">
    <div class="rank-page-header-tab-container">
      <div class="rank-page-header-tab">
        <div @click="switchTab('task')" class="rank-page-header-tab-item" :class="{active:$store.state.rankPage.header.tab === 'task'}">赚严值</div>
        <div @click="switchTab('user')" class="rank-page-header-tab-item" :class="{active:$store.state.rankPage.header.tab === 'user'}">用户榜</div>
        <div @click="switchTab('creator')" class="rank-page-header-tab-item" :class="{active:$store.state.rankPage.header.tab === 'creator'}">创作者榜</div>
      </div>
    </div>
  </v-header>
</template>

<script>
export default {

  methods:{

    switchTab(tab){
      this.$store.dispatch('rankPage/switchHeaderTab',tab)
    }

  }

}
</script>


<style scoped lang="less">
.rank-page-header-tab-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rank-page-header-tab {
  background: #fff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.rank-page-header-tab-item{
  color: #151d36;
  font-size: 14px;
  padding: 0 10px;
  height: 100%;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rank-page-header-tab-item.active {
  background: #151d36;
  color: #fff;
}

</style>
