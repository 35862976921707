<template>
  <div>
    <rank-page-header/>

    <rank-page-user-rank v-show="$store.state.rankPage.header.tab==='user'"/>
    <rank-page-creator-rank v-show="$store.state.rankPage.header.tab==='creator'"/>
    <task :hide-header="true" v-show="$store.state.rankPage.header.tab === 'task'"/>

    <div class="rank-share" @click="$refs.rankSharePost.show()" v-if="$store.state.rankPage.header.tab==='user'">
      <i class="iconfont icon-share"></i> 分享我的排名
    </div>
    <rank-share-post-dialog ref="rankSharePost" :rankType="$store.state.rankPage.tab.userRank"
                            :dayInRange="$store.state.rankPage.query.userRank.daysInRange"/>

  </div>
</template>

<script>
import RankPageHeader from "./components/rankPageHeader";
import RankPageUserRank from "./components/rankPageUserRank";
import RankPageCreatorRank from "./components/rankPageCreatorRank";
import Task from "../personal/task";
import RankSharePostDialog from "../../components/rankSharePostDialog";


export default {
  components: {RankSharePostDialog, Task, RankPageCreatorRank, RankPageUserRank, RankPageHeader},


  async created() {
    const nav = this.$route.query.nav || 'user'
    this.$store.dispatch('rankPage/switchHeaderTab', nav).then()
    this.$store.dispatch('rankPage/loadMyRankInfo').then()
    await this.$store.dispatch('rankPage/loadCreatorRankIssues')

    const issues = this.$store.state.rankPage.creatorRank.issues
    let issue = this.$route.query.issue
    if (!issue || (issue + '').toLowerCase() === 'last') {
      issue = issues[1].issue
    }
    const issueItem = issues.find(i => (i.issue + '') === (issue + ''))
    this.$store.dispatch('rankPage/selectCreatorRankIssue', issueItem.value).then()

  }
}
</script>

<style scoped>
.rank-share {
  position: fixed;
  bottom: 100px;
  right: 0;
  background: #fff1c6;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  font-size: 14px;
  width: 132px;
}

.rank-share .icon-share {
  margin-right: 4px;
  transform: translateY(1px);
}
</style>
