<template>
  <div class="rank-page">
    <rank-page-my-rank-info/>

    <div class="rank-tab">
      <van-tabs ref="tab" :active="$store.state.rankPage.tab.userRank" @input="onTabChange" animated swipeable sticky
                offset-top="10.667vw" @scroll="onTabScroll">
        <van-tab name="browse" title="浏览榜">
          <rank-page-rank-list
            :my-rank-info="$store.state.rankPage.myRankInfo.browseRank"
            unit="份"
            :items="$store.state.rankPage.userRank.browse.pageData.records"
            :loading="$store.state.rankPage.userRank.browse.loading"
            :total="$store.state.rankPage.userRank.browse.pageData.total"
            @loadMore="loadMore('browse')"
          />
        </van-tab>


        <van-tab name="recommend" title="推荐榜">
          <rank-page-rank-list
            :my-rank-info="$store.state.rankPage.myRankInfo.recommendRank"
            unit="份"
            :items="$store.state.rankPage.userRank.recommend.pageData.records"
            :loading="$store.state.rankPage.userRank.recommend.loading"
            :total="$store.state.rankPage.userRank.recommend.pageData.total"
            @loadMore="loadMore('recommend')"
          />
        </van-tab>


        <van-tab name="collect" title="收藏榜">
          <rank-page-rank-list
            :my-rank-info="$store.state.rankPage.myRankInfo.collectRank"
            unit="份"
            :items="$store.state.rankPage.userRank.collect.pageData.records"
            :loading="$store.state.rankPage.userRank.collect.loading"
            :total="$store.state.rankPage.userRank.collect.pageData.total"
            @loadMore="loadMore('collect')"
          />
        </van-tab>


        <van-tab name="unlock" title="下载榜">
          <rank-page-rank-list
            :my-rank-info="$store.state.rankPage.myRankInfo.unlockRank"
            unit="份"
            :items="$store.state.rankPage.userRank.unlock.pageData.records"
            :loading="$store.state.rankPage.userRank.unlock.loading"
            :total="$store.state.rankPage.userRank.unlock.pageData.total"
            @loadMore="loadMore('unlock')"
          />
        </van-tab>


        <van-tab name="award" title="奖励榜">
          <rank-page-rank-list
            :my-rank-info="$store.state.rankPage.myRankInfo.awardRank"
            unit=""
            :items="$store.state.rankPage.userRank.award.pageData.records"
            :loading="$store.state.rankPage.userRank.award.loading"
            :total="$store.state.rankPage.userRank.award.pageData.total"
            @loadMore="loadMore('award')"
          />
        </van-tab>

      </van-tabs>
    </div>


  </div>
</template>

<script>
import RankPageMyRankInfo from "./rankPageMyRankInfo";
import RankPageRankList from "./rankPageRankList";

export default {
  components: {RankPageRankList, RankPageMyRankInfo},
  watch:{
    '$store.state.rankPage.header.tab'(val){
      if(val === 'user'){
        this.$refs.tab.resize()
      }
    }
  },


  methods: {

    // 加载更多
    loadMore(rankName) {
      this.$store.dispatch('rankPage/loadMoreUserRank', {rankName})
    },


    // 切换tab
    onTabChange(tab) {
      this.$store.dispatch('rankPage/switchUserRankTab', tab)
    },


    onTabScroll(e) {
      this.$store.dispatch('rankPage/setTabUserRankFixed',e.isFixed)
    }

  }

}
</script>

<style scoped lang="less">
.rank-page {
  height: calc(100vh - 40px - 50px);
  overflow: auto;
  background: #f4f4f4;
  width: 100vw;
}

.rank-tab {
  margin-top: 42px;
  background: #fff;
}

::v-deep .van-tabs__nav {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
