<template>
  <div class="rank-page-my-rank-info">
    <div class="my-rank-info">
      <div class="my-rank-level">
        <div style="display: flex;align-items: flex-end">
          <div>LV.{{ $store.state.rankPage.myRankInfo.level }}</div>
        </div>
        <div @click="$router.push('/userLevel')" class="my-rank-popularity-next-level">
          LV.{{
            $store.state.rankPage.myRankInfo.hasNextLevel ? $store.state.rankPage.myRankInfo.level + 1 : $store.state.rankPage.myRankInfo.level
          }}
          <van-icon name="arrow"/>
        </div>
      </div>
      <div class="my-rank-progress">
        <div class="my-rank-progress-content"
             :style="'width: '+($store.state.rankPage.myRankInfo.currPoint*100/$store.state.rankPage.myRankInfo.nextLevelMinPoint)+'%;'"></div>
      </div>
      <div class="my-rank-popularity">
        <div class="my-rank-popularity-point" @click="$router.push('/record?tab=TASK')">
          累积奖励严值: {{
            $store.state.rankPage.myRankInfo.currPoint
          }}/{{ $store.state.rankPage.myRankInfo.nextLevelMinPoint }}
          <van-icon name="arrow"/>
        </div>
      </div>
      <div class="my-rank-filter">
        <div class="my-rank-filter-title">排行日期</div>

        <van-popover v-model="popperVisible" v-if="$store.state.rankPage.header.tab === 'user'"
                     trigger="click"
                     @select="selectUserRankDaysInRange"
                     :actions="$store.state.rankPage.options.userRankDaysInRange">
          <template #reference>
            <div class="my-rank-filter-main">{{ $store.state.rankPage.query.userRank.daysInRangeText }}
              <van-icon name="arrow-down" style="margin-left: 8px"/>
            </div>
          </template>
        </van-popover>


        <van-popover v-model="popperVisible" v-else-if="$store.state.rankPage.header.tab==='creator'"
                     trigger="click"
                     style="flex-shrink: 0"
                     :actions="$store.state.rankPage.creatorRank.issues" @select="selectIssue">
          <template>
            <div style="height: 200px;overflow-y: scroll">
              <van-list>
                <van-cell v-for="item in $store.state.rankPage.creatorRank.issues">
                  <div @click="selectIssue(item)" style="text-align: center">{{ item.text }}</div>
                </van-cell>
              </van-list>
            </div>
          </template>
          <template #reference>
            <div class="my-rank-filter-main">{{ $store.state.rankPage.query.creatorRank.issueText }}
              <van-icon name="arrow-down" style="margin-left: 8px"/>
            </div>
          </template>
        </van-popover>
      </div>
    </div>


    <div class="my-rank-icon">
      <img src="https://cdn.001ppt.cn/wxApp/assets/svg/rank_level.svg" mode="widthFix"/>
      <div class="my-rank-icon-level">{{ $store.state.rankPage.myRankInfo.level }}</div>
    </div>
    <div class="go-yanzhi-btn" @click="goTask">
      <div class="left">
        <img src="@/assets/svg/coin.svg"/>
        赚奖励通道
      </div>
      <van-icon name="arrow" style="font-weight: bolder;transform: translateY(1px)"/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      popperVisible: false
    }
  },


  methods: {
    goTask() {
      this.$store.dispatch('rankPage/switchHeaderTab', 'task')
    },


    // 选择用户榜的
    selectUserRankDaysInRange(option) {
      this.$store.dispatch('rankPage/selectUserRankDaysInRange', option.value)
      this.popperVisible = false
    },


    // 创作者榜
    selectIssue(issue) {
      this.$store.dispatch('rankPage/selectCreatorRankIssue', issue.value)
      this.popperVisible = false
    }

  }
}
</script>

<style scoped lang="less">
.rank-page-my-rank-info {
  background: #151d36;
  height: 157px;
  display: flex;
  align-items: center;
  padding: 0 30px 20px 30px;
  position: relative;
}

.rank-page-my-rank-info .my-rank-info {
  flex-grow: 1;
}


.rank-page-my-rank-info .my-rank-level {
  font-size: 30px;
  color: #fbdd9d;
  font-weight: bolder;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.rank-page-my-rank-info .my-rank-progress {
  height: 5px;
  background: #fff;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}

.rank-page-my-rank-info .my-rank-progress-content {
  background: #fbdd9d;
  height: 100%;
  transition: width .3s;
}

.rank-page-my-rank-info .my-rank-popularity {
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rank-page-my-rank-info .my-rank-popularity-point {
  display: flex;
  align-items: center;
}

.rank-page-my-rank-info .my-rank-popularity img {
  height: 12px;
  width: 12px;
  opacity: .3;
  margin-left: 4px;
  transform: translateY(1px);
}

.rank-page-my-rank-info .my-rank-popularity-next-level {
  opacity: .3;
  font-size: 12px;
  transform: translateY(-4px);
  display: flex;
  align-items: center;
}

.rank-page-my-rank-info .my-rank-filter {
  display: flex;
  font-size: 12px;
  margin-top: 12px;
  align-items: center;
}

.rank-page-my-rank-info .my-rank-filter-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  color: #151d36;
  opacity: 0.3;
  padding: 2px 10px;
  border-radius: 100px;
  margin-left: 4px;
}

.rank-page-my-rank-info .my-rank-filter-title {
  color: rgba(255, 255, 255, 1);
  opacity: 0.3;
  flex-shrink: 0;
}


.rank-page-my-rank-info .my-rank-icon {
  width: 135px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.rank-page-my-rank-info .my-rank-icon img {
  width: 110px;
  transform: translateY(-10px);
}

.rank-page-my-rank-info .my-rank-icon-level {
  color: #fff;
  font-size: 24px;
  font-weight: bolder;
  position: absolute;
  left: 75px;
  transform: translateY(-6px);
}


.rank-page-my-rank-info .go-yanzhi-btn img {
  height: 35px;
  width: 35px;
  margin-right: 4px;
}

.rank-page-my-rank-info .go-yanzhi-btn {
  position: absolute;
  background: #fff;
  border-radius: 6px;
  height: 62px;
  bottom: -31px;
  width: 280px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 20px;
  font-weight: bolder;
  justify-content: space-between;
}

.rank-page-my-rank-info .go-yanzhi-btn .left {
  display: flex;
  align-items: center;
}

</style>
